<template>
  <div class="login-page">
    <section class="login">
      <div class="container">
        <div class="box-login">
          <div class="box-form login-form">
            <h4 class="service text-center mb40">Single Sign On</h4>
            <!-- <div class="login-desc">Login to Diplomacy Room</div> -->
            <!-- <form id="form-login" @submit.prevent="submit">
              <div class="form-group">
                <input type="text" v-model="email" id="email" class="form-control" :class="{ 'is-invalid': errorRequest != null }" name="usernmae" placeholder="Username" required>
                <div class="for-icon"><img src="/img/user.svg" class="img-fluid"></div>
                <div class="invalid-feedback" v-if="errorRequest">
                  {{ errorRequest }}
                </div>
              </div>
              <div class="for-fg">
                <input type="password" v-model="password" id="password" class="form-control" name="password" placeholder="Password" required>
                <div class="for-icon"><img src="/img/key.svg" class="img-fluid"></div>
              </div>
              <button type="submit" id="submit-button" class="btn btn-orange w-100"><strong>Masuk</strong></button>
            </form> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import cookie from "js-cookie";

export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      errorRequest: null
    }
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.query.exchToken === undefined) {
      this.$router.push('/home')
      return false;
    } 
    if (this.$route.query.exchToken === "invalidUser") {
      this.$router.push('/forbidden')
      return false;
    } 
    if (this.$route.query.user !== undefined) {
      this.loginManual(this.$route.query.user);
    }
    this.getProfile();
  },
  methods: {
    getProfile() {
      const loader = this.$loading.show({
      container: null,
      lockScroll: true,
      opacity: 1
    });
      this.$axios
        .get(`/SSO_LOGIN`, {
          params: {
            api_token: this.$route.query.exchToken
          }
        }).then(response => {
          const result = response.data.data;
          if (this.ENV_MODE == "development") {
            localStorage.setItem("login", JSON.stringify(result));
          }
          // save data to vuex
          this.$store.commit("SET_USERDATA", result);
          // save data to cookie
          cookie.set("userdata", result, { expires: 1 });
          // redirect after login
          window.location.reload();          
          loader.hide();
        })
    },
    loginManual(user) {
      const data = {}
      data.dosen = {"id":3,"name":"Dosen Test","email":"100010011@uii.ac.id","email_verified_at":null,"created_at":null,"updated_at":"2021-08-06T00:36:09.000000Z","nim":100010011,"eduPersonAffiliation":"Lecturer","api_token":null,"status":true,"token":"278|COoJH554jZDhAZRC0nLUMkN7syQYiJGt1K5mw4TA","token_type":"Bearer"};
      data.siswa = {"id":2,"name":"Student Test","email":"10001001@students.uii.ac.id","email_verified_at":null,"created_at":null,"updated_at":"2021-08-09T04:53:26.000000Z","nim":10001001,"eduPersonAffiliation":"Student","api_token":null,"status":true,"token":"289|7v7gZ8waprdXssdMYg3DjFpKKsnyqtj601CdFh06","token_type":"Bearer"};
      const result = data[user];
      // save data to vuex
      this.$store.commit("SET_USERDATA", result);
      // save data to cookie
      cookie.set("userdata", result, { expires: 1 });
      // redirect after login
      window.location.reload();        
    }
  }
}
</script>